<template>
   <v-app :style="[cssVariable, { background: $vuetify.theme.themes[theme].background }]">
      <div :class="isAuthenticated && !hideFeatures ? 'logged-in platform-grid' : 'platform-grid'">
         <div class="resizable" :auth="hasOrganization">
            <!-- sidebard -->
            <div class="menu-sidebar">
               <Navigation />
            </div>
            <!-- content -->
            <div class="content" id="mainContainer">
               <!--  -->
               <!-- Tryout Bar -->
               <TryoutWarning v-if="isAuthenticated && !hideFeatures" />
               <!--  -->
               <!-- Topbar -->
               <TopBar v-if="isAuthenticated && !hideFeatures" />
               <!--  -->
               <!-- Config Theme Color -->
               <ConfigOrganization v-if="isAuthenticated && !hideFeatures && false" />
               <!--  -->
               <!-- Router -->
               <router-view></router-view>
               <!--  -->
               <!-- Modal HelpDesk autoplay -->
               <HelpDeskAutoPlay />
               <v-snackbar
                  v-model="notification"
                  color="primary"
                  top
                  :timeout="timeout"
                  :vertical="true"
               >
                  {{ mensage }}
                  <template v-slot:action="{ attrs }">
                     <v-btn color="white" text v-bind="attrs" @click="notification = false">
                        Fechar
                     </v-btn>
                  </template>
               </v-snackbar>
               <Notification />
               <bank-integration-alert />
            </div>
         </div>
      </div>
   </v-app>
</template>

<script>
// Core
import { mapActions, mapGetters, mapMutations } from "vuex";
// Assets
import "./style/global.scss";
import "./style/vuetify.scss";
import "@/shared/scss/_layout.scss";
// Components
import Navigation from "@/shared/components/atomicDesign/molecules/Navigation.vue";
import TryoutWarning from "@/shared/components/TryoutWarning.vue";
import TopBar from "@/shared/components/TopBar.vue";
import ConfigOrganization from "@/shared/components/atomicDesign/molecules/ConfigOrganization.vue";
import HelpDeskAutoPlay from "@/shared/components/atomicDesign/organisms/HelpDeskAutoPlay.vue";
import Notification from "@/shared/components/Notification.vue";
import BankIntegrationAlert from "@/shared/components/atomicDesign/molecules/BankIntegrationAlert.vue";

export default {
   name: "App",
   components: {
      Navigation,
      TopBar,
      TryoutWarning,
      ConfigOrganization,
      HelpDeskAutoPlay,
      Notification,
      BankIntegrationAlert
   },

   data() {
      return {
         themeColor: null
      };
   },
   computed: {
      ...mapGetters([
         "colorTheme",
         "organizationName",
         "userAuthenticated",
         "role",
         "chatUserEnable",
         "chatPlannerEnable"
      ]),

      cssVariable() {
         const THEME = this.themeColor;

         if (THEME) {
            return {
               "--primary": `#${THEME.primary}`,
               "--secondary": `#${THEME.secondary}`
            };
         } else {
            return {
               "--primary": `#fff`,
               "--secondary": `#fff`
            };
         }
      },
      isClient() {
         return this.role.client;
      },
      isAuthenticated() {
         return !!this.userAuthenticated;
      },
      theme() {
         return this.$vuetify.theme.dark ? "dark" : "light";
      },
      hasOrganization() {
         return !!this.organizationName;
      },
      hideFeatures() {
         return !!this.$route.meta.hideMenuFeatures;
      }
   },
   methods: {
      ...mapActions(["validateAppAuth", "readConfigTheme", "editTheme"]),
      ...mapMutations(["resetOptions"]),

      runValidation() {
         if (this.$route.params.organization) {
            this.validateAppAuth();
            this.readConfigTheme();
         }
      }
   },
   watch: {
      $route(route) {
         this.runValidation();
         if (route.name !== "cashRegister") this.resetOptions();
      },
      colorTheme(updatedTheme) {
         this.themeColor = updatedTheme;
      }
   },
   created() {
      this.runValidation();
   }
};
</script>
